import { Component, OnInit } from '@angular/core';
import {Item} from '../common/item';
import {Card} from '../common/card/card';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    headerButtons: Item[] = [
        {
            label: 'Demo',
            styleClass: 'btn-primary',
            icon: 'fa-code',
            routerLink: '/demo'
        },
        {
            label: 'Contact Us',
            styleClass: 'btn-white',
            icon: 'fa-envelope',
            routerLink: '/contact-us'
        }
    ];

    features: Card[] = [
        {
            name: 'Rapid development',
            id: 'rapid_development',
            caption: 'Drive innovation at warp speed with UCF\'s rapid development tools',
            color: 'info',
            icon: 'ni-delivery-fast'
        },
        {
            name: 'Cost savings',
            id: 'rapid_development',
            caption: 'Realize exceptional cost savings, especially in complex systems',
            color: 'info',
            icon: 'ni-money-coins'
        },
        {
            name: 'Enhanced quality',
            id: 'rapid_development',
            caption: 'Achieve superior quality by design with UCF\'s framework-driven solutions',
            color: 'info',
            icon: 'ni-satisfied'
        },
        {
            name: 'Heightened security',
            id: 'rapid_development',
            caption: 'Experience heightened security as an inherent feature of UCF\'s robust framework, safeguarding your data with advanced protocols and policies',
            color: 'info',
            icon: 'ni-key-25'
        },
        {
            name: 'Exceptional performance',
            id: 'rapid_development',
            caption: 'Unlock exceptional performance with UCF\'s unique architecture, ensuring unmatched efficiency and speed',
            color: 'info',
            icon: 'ni-spaceship'
        },
        {
            name: 'Infinite scale',
            id: 'rapid_development',
            caption: 'Achieve infinite scalability effortlessly with UCF\'s designed for infinite scalability architecture',
            color: 'info',
            icon: 'ucf-i-full-cloud'
        },
        {
            name: 'Unmatched flexibility',
            id: 'rapid_development',
            caption: 'Lead the way with UCF\'s unmatched flexibility, empowering limitless solution possibilities',
            color: 'info',
            icon: 'ni-atom'
        },
        {
            name: 'Prebuilt features',
            id: 'rapid_development',
            caption: 'Streamline your development journey with UCF\'s array of prebuilt functionality modules, expediting your path to the perfect solution',
            color: 'info',
            icon: 'ni-bulb-61'
        },
        {
            name: 'Enterprise ready',
            id: 'rapid_development',
            caption: 'Streamline your development journey with UCF\'s array of prebuilt functionality modules, expediting your path to the perfect solution',
            color: 'info',
            icon: 'ni-building'
        }
    ]
    constructor() { }

    ngOnInit() {}
}
