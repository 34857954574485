import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SectionsModule} from '../sections/sections.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CardComponent} from './card/card.component';
import {SendRequestComponent} from './send-request/send-request.component';
import {HeaderComponent} from './header/header.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {ImgComponent} from './img/img.component';
import {OutsideClickDirective} from './outside-click.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
        RecaptchaModule,
    ],
    declarations: [
        HeaderComponent,
        CardComponent,
        SendRequestComponent,
        ImgComponent,
        OutsideClickDirective
    ],
    exports: [
        HeaderComponent,
        OutsideClickDirective,
        CardComponent,
        ImgComponent,
        SendRequestComponent
    ],
    providers: []
})
export class CommonComponentsModule {

}
