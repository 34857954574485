import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SectionsModule} from '../sections/sections.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonComponentsModule} from '../common/common-components.module';
import {DemoComponent} from './demo.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule, NgbModule, CommonComponentsModule
    ],
    declarations: [DemoComponent],
    exports: [DemoComponent],
    providers: []
})
export class DemoModule {
}
