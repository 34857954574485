import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: []
})

export class ContactUsComponent {
    // @Input()

}
