import {BrowserModule} from '@angular/platform-browser';
import {InjectionToken, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing';

import {AppComponent} from './app.component';
import {LandingComponent} from './landing/landing.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {FooterComponent} from './shared/footer/footer.component';

import {HomeModule} from './home/home.module';
import {DropdownMenuComponent} from './shared/navbar/dropdown-menu/dropdown-menu.component';
import {FeaturesModule} from './features/features.module';
import {ContactUsModule} from './contact-us/contact-us.module';
import {DemoModule} from './demo/demo.module';
import {BenefitsModule} from './benefits/benefits.module';
import {FaqModule} from './faq/faq.module';
import {ProductsModule} from './products/products.module';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {APP_CONFIG, AppConfig} from './app.config';
import {NotFoundComponent} from './not-found/not-found.component';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {InvestorRelationsModule} from './investor-relations/investor-relations.module';

export const env = new InjectionToken('ENVIRONMENT');

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        NavbarComponent,
        DropdownMenuComponent,
        FooterComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HomeModule,
        FeaturesModule,
        FaqModule,
        InvestorRelationsModule,
        ContactUsModule,
        DemoModule,
        BenefitsModule,
        HomeModule,
        ProductsModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        { provide: APP_CONFIG, useValue: AppConfig },
        { provide : 'env' , useValue: environment}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
