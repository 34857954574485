import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SectionsModule } from '../sections/sections.module';
import {CommonComponentsModule} from '../common/common-components.module';
import {FaqComponent} from './faq.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule, NgbModule, CommonComponentsModule
    ],
    declarations: [ FaqComponent ],
    exports:[ FaqComponent ],
    providers: []
})
export class FaqModule { }
