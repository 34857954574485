<img src="{{src}}" class="{{styleClass}} pointer" (click)="open()">


<div *ngIf="imageFullscreenView" class="image-fullscreen-view">
    <div class="lightbox-wrapper">
        <div class="close" (click)="close()"><i class="fa fa-close"></i></div>
        <div class="lightbox-div" #lightboxDiv>
            <!--        <div *ngIf="showLoading" class="pre-loader">-->
            <!--            <div class="mnml-spinner"></div>-->
            <!--        </div>-->
            <div class="lightbox-image-main">
                <div class="lightbox-image">
                    <div class="custom-image-main"  (outsideClick)="onClickedOutside($event)" >
                        <img src="{{src}}">
                    </div>
                </div>
                <!--            <div [dir]="direction" [ngClass]="{'show': title, 'hide': !title}" class="caption">{{ title }}</div>-->
            </div>
        </div>
    </div>
</div>
