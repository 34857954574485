import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location, PopStateEvent} from '@angular/common';
import {MenuItem} from './MenuItem';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    constructor(public location: Location, private router: Router) {
    }

    dropdownXLItems: MenuItem[] = [];

    ngOnInit() {
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });

        this.dropdownXLItems = [
            {
                label: 'UC-AP - Enterprise Application Platform',
                description: ' Platform as a Service (PaaS) product.',
                icon: 'ni-spaceship',
                iconClass: 'bg-success',
                textClass: 'text-success',
                routerLink: 'products/uc_ap'
            },{
                label: 'UC-ECS - Exclusive Cloud Solution',
                description: 'Hosted by us, outside of PaaS infrastructure',
                icon: 'ni-cloud-upload-96',
                iconClass: 'bg-primary',
                textClass: 'text-primary',
                routerLink: 'products/uc_ecs'
            },{
                label: 'UC-EPS - Exclusive onPremise Solution',
                description: 'We build the product, hosted on premise',
                icon: 'ni-money-coins',
                iconClass: 'bg-info',
                textClass: 'text-info',
                routerLink: 'products/uc_eps'
            },{
                label: 'UC-PLP - Platform Licensing Partnership',
                description: 'Build your own product on the platform. Negotiable the level of participation by our team.',
                icon: 'ni-palette',
                iconClass: 'bg-default',
                textClass: 'text-default',
                routerLink: 'products/uc_plp'
            },

        ];
    }

}
