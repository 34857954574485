<div class="position-relative">
    <!-- shape Hero -->
    <section class="section section-lg section-shaped pb-150">
        <div class="shape shape-style-4 shape-default">
            <span><i class=" ucf-i-ucf-cog-lite big"></i></span>
            <span><i class=" ucf-i-ucf-cog-lite bigger"></i></span>
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <span></span>-->
<!--            <img src="./assets/img/ucf/eksperiment2.png" style="width: 100%; opacity: 0.5">-->

        </div>
        <div class="container pt-50  d-flex">
            <div class="col px-0">
                <div class="row">
                    <div class="col-lg-10">
                        <h1 class="display-1  text-white">{{title}}
                            <span class="display-3">{{caption}}</span>
                        </h1>
                        <p class="lead  text-white">{{description}}</p>
                        <div class="btn-wrapper" *ngIf="buttons != null && buttons.length > 0">
                            <a *ngFor="let button of buttons" [routerLink]="[button.routerLink]" class="btn btn-icon mb-3 mb-sm-0" [ngClass]="button.styleClass">
                                <span class="btn-inner--icon"><i class="fa " [ngClass]="button.icon"></i></span>
                                <span class="btn-inner--text">{{button.label}}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SVG separator -->
        <div class="separator separator-bottom separator-skew">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </section>
    <!-- 1st Hero Variation -->
</div>
