<main>
    <app-header
            caption="Find quick answers to common questions about our platform."
            description="Create your App - Quick, Safe, Scalable and Fully customizable!"
    ></app-header>

    <section class="section section-lg pt-lg-0 ">
        <div class="container">
            <div class="row ">
                <div class="col-lg-6 pt-lg-6" *ngFor="let benefit of benefits">
                    <app-card [card]="benefit" [styleType]="'style_2'" [showBorder]="false" [showShadow]="false"></app-card>
                </div>
            </div>
        </div>
    </section>
</main>
