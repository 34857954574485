<main>
    <app-header
            caption="An extensive array of flawless functionalities"
            description="Create your App Quick, Safe, Scalable and Fully customizable!"
    ></app-header>

    <section class="section section-lg pt-lg-0 mt-0" *ngIf="selectedFeature == null">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 pt-lg-4 mb-2" *ngFor="let feature of features">
                    <div class="card {{feature.styleClass}} border">
                        <div class="card-body">
                            <div class="d-flex px-2">
                                <div>
                                    <div class="icon text-{{feature.color}}  mb-4 ">
                                        <i class="ni " [ngClass]="feature.icon"></i>
                                    </div>
                                </div>
                                <div class="pl-2">
                                    <h6 class="title text-{{feature.color}} font-weight-bold ">{{feature.name}}</h6>


                                </div>
                            </div>
                            <div>
                                <p class="card-caption-sm">{{feature.caption}}</p>
                            </div>
                            <div *ngIf="feature.tags.length > 0" class="mb-2">
                                <span *ngFor="let tag of feature.tags"
                                      class="badge badge-pill badge-secondary mr-1 mb-1">{{tag}}</span>
                            </div>
                            <div class="text-center mt-4">
                                <span class="btn btn-sm" (click)="showMore(feature)">Show
                                    more
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-lg pt-lg-0 mt-0" *ngIf="selectedFeature != null">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">

                    <div class="text-center px-2 mb-4">
                        <div class="text-center m-auto">
                            <div class="icon icon-xl text-{{selectedFeature.color}} text-center m-auto mb-4 ">
                                <i class=" text-center" [ngClass]="selectedFeature.icon"></i>
                            </div>
                        </div>
                        <div class=" pl-4">
                            <h3 class="title font-weight-bold ">{{selectedFeature.name}}</h3>
                        </div>

                    </div>
                    <div *ngIf="selectedFeature.tags.length > 0" class="mb-4 mt-4  text-center">
                                    <span *ngFor="let tag of selectedFeature.tags"
                                          class="badge badge-pill badge-{{selectedFeature.color}} mr-1 mb-1">{{tag}}</span>
                    </div>
                    <div>
                        <p class="card-description" [innerHTML]="selectedFeature.description"></p>
                    </div>
                    <div class="mt-5 text-center">
                        <button type="button" class="btn btn-sm btn-outline-dark" (click)="goBack()">Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
