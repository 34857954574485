import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-investor-relations',
    templateUrl: './investor-relations.component.html',
    styleUrls: []
})

export class InvestorRelationsComponent {


}
