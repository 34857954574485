<main>
    <app-header ></app-header>
    <section class="section section-lg pt-lg-0 section-contact-us">
        <div class="container">
            <div class="row justify-content-center mt--150">
                <div class="col-lg-8">
                    <app-send-request title="Connect With Us" caption="Whether you have questions, feedback, or just want to say hello, we're more than happy to hear from you. Reach out to us anytime!"></app-send-request>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-lg pt-0">
        <div class="container">
            <div class="card bg-gradient-success shadow-lg border-0">
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-white">Didn't see our feature list yet?</h3>
                            <p class="lead text-white mt-3">Discover the range of benefits and capabilities our platform offers. Explore now and unlock the full potential of our services!</p>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <a class="btn btn-lg btn-block btn-white" [routerLink]="['/features']">See our features</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
