import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

import {SectionsModule} from '../sections/sections.module';
import {CommonComponentsModule} from '../common/common-components.module';
import {InvestorRelationsComponent} from './investor-relations.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        SectionsModule,
        NgbModule,
        CommonComponentsModule
    ],
    declarations: [InvestorRelationsComponent],
    exports: [InvestorRelationsComponent],
    providers: []
})
export class InvestorRelationsModule {
}
