import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../shared/navbar/MenuItem';
import {Item} from '../item';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: []
})

export class HeaderComponent implements OnInit {
    @Input() title?: string;
    @Input() caption?: string;
    @Input() description?: string;
    @Input() buttons?: Item[];

    focus;
    focus1;
    ngOnInit(): void {
    }
}
