import {Component} from '@angular/core';

@Component({
    selector: 'app-demo',
    templateUrl: './demo.component.html',
    styleUrls: []
})

export class DemoComponent {
    // @Input()

}
