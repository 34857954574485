import {Component, Inject, Input, OnInit} from '@angular/core';
import {AjaxService} from '../ajax/ajax.service';

@Component({
    selector: 'app-send-request',
    templateUrl: './send-request.component.html',
    styleUrls: []
})

export class SendRequestComponent implements OnInit {
    @Input() title: string;
    @Input() caption: string;
    @Input() requestType?: 'message' | 'schedule_demo' = 'message';
    focus = {
        fistName: false,
        lastName: false,
        email: false,
        companyName: false,
        jobTitle: false,
        message: false,
    };
    messageRequest = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        message: ''
    };
    demoRequest = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        jobTitle: ''
    };
    successfullySubmitted: boolean = false;
    showRecaptcha: boolean = false;
    submitInProgress: boolean = false;
    activeForm: any;

    constructor(@Inject('env') private env, private ajaxService: AjaxService) {
        // You can use config.apiEndpoint now
    }

    ngOnInit(): void {
    }

    onMessageSubmit(messageForm: any) {
        messageForm.submitted = true;
        if (messageForm.valid) {
            this.activeForm = messageForm;
            this.showRecaptcha = true;
            // TODO JELENA send a message

        }
    }

    onDemoRequestSubmit(demoForm: any) {
        demoForm.submitted = true;
        if (demoForm.valid) {
            this.activeForm = demoForm;
            this.showRecaptcha = true;


        }
    }

    resolvedRecaptcha($event: string) {
        this.showRecaptcha = false;
        this.submitInProgress = true;
        const req = this.activeForm.value;
        req.recaptchaToken = $event;
        if (this.requestType === 'message') {
            this.ajaxService.post(this.env.messageAPIUrl + 'contactUs', req).then(() => {

                this.activeForm.resetForm();
                this.submitInProgress = false;
                this.successfullySubmitted = true;

            }).catch(() => {
                // TODO JELENA Something went wrong
                this.submitInProgress = false;
            });
        } else {
            this.ajaxService.post(this.env.messageAPIUrl + 'scheduleDemo', req).then(() => {

                this.activeForm.resetForm();
                this.submitInProgress = false;
                this.successfullySubmitted = true;

            }).catch(() => {
                this.submitInProgress = false;
                // TODO JELENA Something went wrong
            });
        }


    }
}
