<main>
    <app-header
            caption="See our products"
    ></app-header>
    <section class="section section-md " id="uc_ap">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-lg-12 ">
                    <div class="d-flex mb-4">
                        <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i class="ni ni-spaceship"></i>
                        </div>
                        <div class="pl-4">
                            <h2 class="display-3 text-success">UC-AP - Enterprise Application Platform</h2>
                        </div>
                    </div>
                    <p>UC-AP serves as the cornerstone of our PaaS ecosystem, seamlessly integrating the advantages of Cloud computing, SaaS models, and modern metadata-driven system architectures.</p>
<p>Much like the shared deployment model in SaaS and Cloud environments, where multiple entities utilize the same infrastructure, UC-AP facilitates the deployment of multiple products on a unified platform, encompassing both software and hardware infrastructure.</p>
<p> Each product within UC-AP can operate as either a SaaS or a single-tenant solution, boasting its own dedicated database, storage, search engine, and domain. Despite this isolation, all products benefit from the same underlying platform for essential features, security, scalability, and robustness. </p>
<p> Designed for versatility, products on UC-AP can be developed using either no-code or low-code methodologies, offering highly flexible solutions. In a manner akin to SaaS systems, individual tenants can exert significant customization over their solutions, with the ability to build anything from custom workflows to custom fields and entities.</p>
<p>UC-AP products can be crafted by our team, third-party professionals, or potentially even by users themselves in future iterations of the platform.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-md bg-secondary" id="uc_ecs">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-lg-12 ">
                    <div class="d-flex mb-4">
                        <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i class="ni ni-cloud-upload-96"></i>
                        </div>
                        <div class="pl-4">
                            <h2 class="display-3 text-primary">UC-ECS - Exclusive Cloud Solution</h2>
                        </div>
                    </div>
                    <p>UC-ECS is designed for larger-scale projects, focusing on flexibility and security, while leveraging Cloud and Metadata driven platform paradigms.</p>
<p>In the UC-ECS model, products utilize the same foundational software platform but have the option for more extensive custom coding when needed, while still retaining common infrastructure. This ensures alignment with platform evolution, all new platform features accessible to UC-ECS products.</p>
<p>For deployment, UC-ECS products are hosted on chosen Cloud infrastructure, each with its dedicated Virtual Private Cloud (VPC) for secure, isolated infrastructure.</p>
<p>While UC-AP provides enterprise-level security in the Platform as a Service (PaaS) model, UC-ECS offers benefits of complete isolation for sectors like government and finance, without sacrificing the benefits of rapid development and customization capabilities.</p>
<p>UC-ECS and UC-EPS provide greater flexibility and customization than UC-AP, allowing for extensive customization to meet the project needs.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-md " id="uc_eps">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-lg-12 ">
                    <div class="d-flex mb-4">
                        <div class="icon icon-shape bg-gradient-info rounded-circle text-white">
                            <i class="ni ni-money-coins "></i>
                        </div>
                        <div class="pl-4">
                            <h2 class="display-3 text-info">UC-EPS - Exclusive onPremise Solution</h2>
                        </div>
                    </div>
                    <p>UC-EPS, while similar to UC-ECS, is tailored for customers who prefer on-premise deployment over cloud-based solutions.</p>
<p> Just like UC-ECS, UC-EPS is designed for larger-scale projects, emphasizing enhanced flexibility and security while leveraging platform paradigms.</p>
<p>In the UC-EPS model, products are constructed on the same foundational software platform, offering extensive flexibility with the option to develop entire custom modules for complex challenges beyond the scope of no-code/low-code solutions. This approach ensures that a significant portion of the product, along with infrastructure, microservices, and paradigms, remains consistent. While certain portions benefit from tailored solutions, they still utilize the common infrastructure, ensuring alignment with platform evolution driven by the customer's needs.</p>
<p>Regarding deployment, in the UC-EPS model, products are deployed by the customer on their chosen infrastructure, granting them full control over product security and deplyment.</p>
<p>While UC-AP offers the benefits of the Platform as a Service (PaaS) model and UC-ECS provides cloud scalability and simplicity, certain sectors such as sensitive government projects and financial institutions demand highly dedicated and secured solutions. UC-EPS retains rapid development capabilities and a no-code/low-code environment while addressing scalability and isolation requirements through on-premise deployment.</p>
<p>Both UC-ECS and UC-EPS offer heightened flexibility and customizable solutions compared to UC-AP, accommodating extensive custom development to meet specific project needs.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-md bg-secondary" id="uc_plp">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-lg-12 ">
                    <div class="d-flex mb-4">
                        <div class="icon icon-shape bg-gradient-default rounded-circle text-white">
                            <i class="ni ni-palette"></i>
                        </div>
                        <div class="pl-4">
                            <h2 class="display-3 text-default">UC-PLP - Platform Licensing Partnership</h2>
                        </div>
                    </div>
                    <p>UC-PLP offers a solution for customers seeking to harness the flexibility and power of the UCF platform and framework while maintaining complete control over the development process.</p>
<p>In this model, customers receive a license to either utilize or extend the UCF platform according to their specific needs. Depending on the needs, the customer would either fork UCF, or keep it as is, with desired features added in by our team, and build the rest in-house.</p>
<p>The customer could build a customized PaaS solution of their own, based on UCF, or leverage it to develop a highly adaptable Enterprise SaaS product.</p>
<p>The possibilities are limitless. Our role is to provide access to the source code, offer training, and assist with the project while empowering your teams to assume full responsibility for development.</p>
                </div>

            </div>
        </div>
    </section>
</main>
