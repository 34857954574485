<div class="card " *ngIf="styleType === 'style_1'" [ngClass]="{'shadow': showShadow, 'border-0': !showBorder, '{{styleClass}}': styleClass != null }">
    <div class="card-body py-5">
        <div class="icon text-{{card.color}}  mb-4">
            <i class="ni " [ngClass]="card.icon"></i>
        </div>
        <h6 class="text-{{card.color}} text-uppercase">{{card.name}}</h6>
        <p class="description mt-3">{{card.caption}}</p>
        <div *ngIf="card.tags.length > 0" class="mb-3">
            <span *ngFor="let tag of card.tags" class="badge badge-pill badge-{{card.color}}">{{tag}}</span>
        </div>
        <button
                type="button"
                class="btn mt-4 btn-sm btn-outline-{{card.color}}"
                popoverClass="card-popover-custom-class"
                placement="bottom"
                ngbPopover="{{card.description}}">
            Learn more
        </button>
        <a href="javascript:void(0)" class="btn btn-primary mt-4" *ngif="card.routerLink">Learn more</a>
    </div>
</div>

<div class="card {{styleClass}}" *ngIf="styleType === 'style_2'" [ngClass]="{'shadow': showShadow, 'border-0': !showBorder }">
    <div class="card-body">
        <div class="d-flex px-2">
            <div>
                <div class="icon icon-shape bg-{{card.color}} rounded-circle text-white">
                    <i class="ni" [ngClass]="card.icon"></i>
                </div>
            </div>
            <div class="pl-4">
                <h5 class="title font-weight-bold text-{{card.color}}">{{card.name}}</h5>
                <p>{{card.caption}}</p>
<!--                <a href="javascript:void(0)" class="text-success">Learn more</a>-->
            </div>
        </div>
    </div>
</div>

<div class="card {{styleClass}} border-{{card.color}}" *ngIf="styleType === 'style_3'" [ngClass]="{'shadow': showShadow, 'border-0': !showBorder }" >
    <div class="card-body">
        <div class="d-flex px-2">
            <div>
                <div class="icon text-{{card.color}}  mb-4 ">
                    <i class="ni " [ngClass]="card.icon"></i>
                </div>
            </div>
            <div class="pl-4">
                <h5 class="title font-weight-bold ">{{card.name}}</h5>
                <p class="card-caption-sm">{{card.caption}}</p>

            </div>
        </div>
        <div>
            <div *ngIf="card.tags.length > 0" class="mb-2">
                <span *ngFor="let tag of card.tags" class="badge badge-pill badge-{{card.color}} mr-1 mb-1">{{tag}}</span>
            </div>
            <p class="card-description" [innerHTML]="card.description"></p>
        </div>
    </div>
</div>
