<nav id="navbar-main" class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned">
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/home']">
      <img src="./assets/img/brand/ucf_logo_white.png">
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbar_global" [ngbCollapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/home']">
              <img src="./assets/img/brand/ucf_u_logo.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed = !isCollapsed"
                  [attr.aria-expanded]="!isCollapsed" aria-controls="navbar_global">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" data-toggle="dropdown" [routerLink]="['/products']" role="button">
            <i class="ni ni-ui-04 d-lg-none"></i>
            <span class="nav-link-inner--text">Products and services</span>
          </a>
          <div class="dropdown-menu" >
            <app-dropdown-menu [items]="dropdownXLItems" [large]="true"></app-dropdown-menu>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" [routerLink]="['/features']" role="button">
            <i class="ni ni-atom d-lg-none"></i>
            <span class="nav-link-inner--text">Features</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link no-caret"[routerLink]="['/benefits']" role="button">
            <i class="fa fa-thumbs-o-up d-lg-none"></i>
            <span class="nav-link-inner--text">Benefits</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" [routerLink]="['/investor-relations']" role="button">
            <i class="ucf-i-question-mark d-lg-none"></i>
            <span class="nav-link-inner--text">Investor Relations</span>
          </a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link no-caret" [routerLink]="['/contact-us']" role="button">
            <i class="fa fa-envelope d-lg-none"></i>
            <span class="nav-link-inner--text">Contact Us</span>
          </a>

        </li>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <a href="https://www.youtube.com/@UCFPaaS" target="_blank" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
              <i class="fa fa-regular fa-rocket mr-2"></i>
            </span>
            <span class="nav-link-inner--text">See it in action</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
