import {Component, OnInit} from '@angular/core';
import {Card} from '../common/card/card';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: []
})

export class FeaturesComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };
    selectedFeatureId: string;
    selectedFeature: Card;
    focus;
    focus1;
    features: Card[] = [
        {
            name: 'Fast No-Code or Low-Code Development',
            id: 'fast_no_code_or_low_code_development',
            caption: 'Rapidly craft stunning products with minimal coding.',
            description: '<p>Experience the power of rapid development with UCF\'s no-code or low-code approach. Whether you\'re a seasoned developer or just starting out, our platform empowers you to build remarkable products swiftly and effortlessly.</p> <p>Say goodbye to lengthy coding sessions and hello to intuitive tools that accelerate your development journey. With UCF, unleash your creativity and bring your vision to life in record time.</p>',
            icon: 'ucf-i-fast',
            color: 'primary',
            tags: ['rapid', 'no-code', 'low-code', 'efortless', 'intuitive']
        },
        {
            name: 'Effortless SaaS Software Development',
            id: 'effortless_saas+_software_development',
            caption: 'UCF - Where SaaS is seamlessly woven into your product.',
            description: 'UCF offers native and versatile support for SaaS product development. With UCF, SaaS software development seamlessly integrates into your workflow, requiring minimal effort. Our intuitive tools and streamlined processes empower you to focus on innovation rather than grappling with the technical complexities of SaaS software. Accelerate your time to market and stay ahead in today\'s dynamic digital landscape by effortlessly bringing your SaaS product to life.',
            icon: 'ucf-i-easy',
            color: 'primary',
            tags: ['SaaS', 'native', 'robust']
        },
        {
            id: 'dual_level_product_configuration', name: 'Dual-Level Product Configuration',
            caption: 'Empowering both architects and tenants for tailored experiences.',
            description: 'With UCF\'s dual-level product configuration, you have the flexibility to shape your software environment at two distinct levels. At the build time, architects can effortlessly modify the fundamental design of the product, ensuring it aligns perfectly with overarching objectives. Meanwhile, tenants enjoy the autonomy to perform individualized run-time modifications, tailoring their experience within the SaaS system to their specific needs. This unique approach fosters unparalleled flexibility and adaptability, allowing each participant to create and customize their system according to their unique requirements.',
            icon: 'ucf-i-dual-level',
            color: 'primary',
            tags: ['Product-level', 'Tenant-level', 'Build-time', 'Run-time', 'Flexibility', 'Adaptability']
        },
        {
            id: 'advanced_multi_tenant_system_modeling', name: 'Advanced Multi-Tenant System Modeling',
            caption: 'Engineer intricate SaaS ecosystems with diverse tenant types.',
            description: 'Elevate your platform with UCF\'s Advanced Multi-Tenant System Modeling, a cutting-edge capability that empowers you to construct complex information systems supporting multifaceted business models. With this feature, aptly named "SaaS systems with Multiple Tenant Types," you transcend traditional limitations by seamlessly configuring various tenant types, each tailored with distinct functionalities, user interfaces, user types, and dedicated portals. Dive into the realm of advanced customization, where you orchestrate intricate ecosystems that cater to diverse user needs and business requirements. UCF enables you to architect dynamic solutions that drive innovation and competitiveness in today\'s ever-evolving digital landscape.',
            icon: 'ucf-i-diverse-thicker',
            color: 'primary',
            tags: ['Advanced', 'Multi-tenancy', 'Complex IS', 'Tailored', 'Competitive advantage']
        },
        {
            id: 'tailored_user_typing', name: 'Tailored User Typing',
            caption: 'Empower diverse user journeys even within a single tenant.',
            description: 'UCF\'s Tailored User Typing feature revolutionizes SaaS system creation by enabling the creation of multiple user types within any single tenant type or entirely independent. This advanced functionality goes beyond traditional user management, allowing you to define distinct user types with varying fields, permissions, interfaces, and access to different portals. Seamlessly orchestrate diverse user journeys tailored to the unique needs of each user type, enhancing usability and efficiency across your platform. With UCF, unleash the power of flexible user typing to drive innovation and user satisfaction.',
            icon: 'ucf-i-multi-user-types',
            color: 'primary',
            tags: ['User typing', 'Role customization', 'Diverse journeys', 'Tailored experience']
        },
        {
            id: 'custom_entities', name: 'Custom Entities',
            caption: 'Empower dynamic data modeling with Custom Entities.',
            description: 'Define and create your own data structures within the platform, allowing you to organize and manage information in a way that aligns perfectly with their business requirements.',
            icon: 'ucf-i-new-entity',
            color: 'primary',
            tags: ['dynamic-data-modeling', 'runtime', 'customization', 'tenant-configuration']
        },
        {
            id: 'entity_inheritance', name: 'Entity Inheritance',
            caption: 'Unleash hierarchical data modeling with Entity Inheritance.',
            description: 'UCF\'s Entity Inheritance feature empowers users to extend entities, akin to Object-Oriented Programming principles, facilitating specialized behavior of existing entities. With support for polymorphism, entities of a common base type can be seamlessly managed within the same lists and searches. For instance, extending a base user entity to create specialized entities like employee adds specific fields while maintaining cohesive data management. Dive into multi-level inheritance for intricate data hierarchies, unlocking limitless possibilities for tailored data modeling within your system.',
            icon: 'ucf-i-inheritance',
            color: 'primary',
            tags: ['entity', 'inheritance', 'hierarchical', 'data-modeling', 'polymorphism']
        },
        {
            id: 'entity-relationship_model_support', name: 'Entity-Relationship Model Support',
            caption: 'Harness the power of relationships with UCF\'s comprehensive ER model support',
            description: 'UCF boasts full support for the Entity-Relationship Model, ensuring comprehensive data coherence and integrity within your system. Navigate relationships targeting specific entities across inheritance trees with ease, while embracing polymorphism for dynamic relationship management. With UCF\'s robust support for the Entity-Relationship Model, unlock the full potential of your data architecture, facilitating seamless data interactions and fostering a cohesive data ecosystem.',
            icon: 'ucf-i-er-model-big',
            color: 'primary',
            tags: ['er-model', 'data-coherence', 'relationship-management', 'polymorphism', 'data-architecture']
        },
        {
            id: 'cross_relationship_data_utilization', name: 'Cross-Relationship Data Utilization',
            caption: 'Seamlessly utilize data across relationships anywere in your system.',
            description: 'UCF\'s Cross-Relationship Data Utilization feature empowers you to seamlessly utilize data across any number of relationships within your system. From designing pages and list views to implementing logic, functions, forms and API interactions, access and leverage data effortlessly across a myriad of interconnected relationships. Whether traversing multiple levels of relationships for complex criteria or leveraging related data for list views, unlock the full potential of your interconnected data model with UCF\'s comprehensive capabilities',
            icon: 'ucf-i-data-across-relationships',
            color: 'primary',
            tags: ['data-access', 'your-data', 'data-relationships', 'cross-utilization', 'integrated-data-access']
        },
        {
            id: 'custom_entity_relationships', name: 'Custom entity relationships',
            caption: 'Empower tenants with flexible data structuring.',
            description: 'UCF empowers tenants and administrators with the ability to effortlessly create additional custom relationships between entities, facilitating unparalleled product customization. This feature enables tenants to tailor their product\'s data structure to meet their specific needs, fostering flexibility and adaptability within the platform. With UCF, tenants can unleash their creativity and achieve highly customized solutions that align perfectly with their unique requirements.',
            icon: 'ucf-i-new-relationship',
            color: 'primary',
            tags: [ 'custom-relationships', 'tenant-empowerment', 'data-structuring', 'product-customization']
        },
        {
            id: 'enterprise_entity_lifecycle_management', name: 'Enterprise Entity Lifecycle Management',
            caption: 'Fine-tune entity lifecycles with UCF\'s robust Lifecycle Management.',
            description: 'UCF\'s Advanced Entity Lifecycle Management offers a meticulously designed system supporting multiple standard business concepts across five distinct lifecycle statuses/levels. Features include entity depreciation and deactivation, soft-deletion, undeletion capabilities, permanent/hard deletion options, and the ability to reference a deleted entity from a relationship (with partial visibility). With UCF, maintain full control over your data\'s lifecycle, ensuring precision and flexibility in managing entity lifecycles according to your business needs.',
            icon: 'ucf-i-trash-undo',
            color: 'primary',
            tags: [ 'custom-relationships', 'tenant-empowerment', 'data-structuring', 'product-customization']
        },
        {
            id: 'entity_relationship_deletion_policies', name: 'Entity Relationship Deletion Policies',
            caption: 'Tailor entity deletion behavior for optimal data management and user experience.',
            description: 'UCF offers extensive flexibility in configuring entity relationship behavior, particularly concerning entity deletion scenarios. With multiple configurable policies, in conjunction with Advanced Lifecycle Management statuses, users can ensure the best possible user experience without risking data loss or encountering deletion challenges. Whether preserving data integrity, managing cascading deletions, or customizing behaviors for specific use cases, UCF empowers users to finely tune entity relationship dynamics for seamless data management.',
            icon: 'ucf-i-trash-question',
            color: 'primary',
            tags: [ 'entity-deleteion-behavior', 'policies', 'data-integrity', 'cascading-deletions', 'user-interaction']
        },
        {
            id: 'custom_fields', name: 'Custom fields',
            caption: 'Capture additional data points or attributes essential for efficiently tracking and analyzing unique information crucial to your business processes.',
            description: '<p>Custom fields are a cornerstone of flexibility and personalization within our system. They allow you to tailor data collection, organization, and presentation to suit your unique needs. Whether you\'re managing customer information, tracking project details, or organizing inventory, custom fields empower you to capture and utilize data in a way that aligns perfectly with your workflow.</p><p>Custom fields are additional data fields that users can create and attach to records within a system. Unlike standard predefined fields, which are often generic and limited in scope, custom fields enable users to define specific attributes or properties relevant to their processes. These fields can capture diverse data types such as text, numbers, dates, checkboxes, dropdown lists, and more.</p>',
            icon: 'ucf-i-new-field',
            color: 'primary',
            tags: []
        },
        {
            id: 'customizable_field_settings', name: 'Customizable Field Settings',
            caption: 'Empower administrators and end-users with configurable field settings, resulting in dynamic forms with powerful validation, rich features and enhanced user interactions.',
            description: 'UCF provides robust capabilities for configuring fields, granting administrators and end-users the freedom to tailor appearance styles, field labels, comprehensive validations, and field behavior. Whether optimizing user interface aesthetics, enforcing data integrity through validation rules, or adjusting field behavior for specific use cases, UCF offers unparalleled flexibility in shaping the behavior of fields within the system. For instance, users can configure field behavior settings to automatically populate a field based on a formula derived from two other fields, even if those fields are connected via a relationship. Empower your users to customize their experience and optimize workflows with UCF\'s versatile field behavior configuration options.',
            icon: 'ucf-i-fos-top-right-cog',
            color: 'primary',
            tags: [ 'entity-deleteion-behavior', 'policies', 'data-integrity', 'cascading-deletions', 'user-interaction']
        },
        {
            id: 'customizable_permissions', name: 'Customizable permissions',
            caption: 'Empower organizations to optimize access control, enhance security, and streamline collaboration',
            description: 'Customizable permissions refer to the ability to define and modify access rights for individual users or groups within a system. Instead of employing a one-size-fits-all approach, where every user has the same level of access, customizable permissions allow administrators to assign specific privileges. These privileges can include viewing, editing, creating, deleting, or sharing content, among other actions.',
            icon: 'ucf-i-key',
            color: 'primary',
            tags: ['tenant-empowerment', 'data-integrity',  'user-interaction']
        },
        {
            id: 'workflows_and_business_processes', name: 'Workflows and business processes',
            caption: 'Empower organizations to design, automate, and refine workflows that align with their strategic objectives, improving efficiency and delivering superior outcomes.',
            description: '<p>In today\'s dynamic business environment, efficiency and agility are paramount. Custom workflows and business processes within our platform empower you to design, automate, and optimize operations according to your unique requirements. Whether you\'re managing projects, handling customer interactions, or coordinating internal tasks, custom workflows offer a tailored approach to streamline processes and drive productivity.</p><p>Custom workflows and business processes are structured sequences of steps that define how tasks, data, and decisions flow within your organization. Unlike rigid predefined workflows, custom workflows allow you to configure processes based on your specific business logic, rules, and objectives. This flexibility enables you to automate repetitive tasks, enforce consistency, and ensure compliance while adapting to changing business needs.</p>',
            icon: 'fa fa-cogs',
            color: 'primary',
            tags: ['tenant-empowerment', 'data-integrity',  'user-interaction']
        },
        {
            id: 'policy_criteria_and_function_driven', name: 'Policies, criteria and functions',
            caption: 'Empower organizations to tailor their digital environments, automate processes, enhance user experiences, and drive innovation.',
            description: '<p>Discover the power of customization within our platform through customizable policies, criteria, and functions hubs for accessing information, engaging with content, and performing tasks efficiently.</p>\n' +
                '<p><div><b>Customizable Policies</b></div>Shape platform usage with tailored rules and standards. From data security to user conduct, enforce policies automatically and adapt them on-the-fly to meet evolving needs and regulatory requirements.</p>' +
                '<p><div><b>Custom Criteria</b></div>Drive automation with precision. Define conditions, triggers, and thresholds that automatically activate actions such as lead routing, content personalization, and notification workflows. Continuously refine criteria using data insights for optimal performance.</p>' +
                '<p><div><b>Custom Functions</b></div>Extend and innovate. Develop custom functions to expand platform capabilities. Seamlessly integrate these custom functions with existing modules and external services, enabling unique and efficient outcomes.</p>',
            icon: 'ni-atom',
            color: 'primary',
            tags: ['tenant-empowerment', 'user-interaction']
        },
        {
            id: 'multilingual_data', name: 'Multilingual data',
            caption: 'Enabling seamless cross-cultural communication, content management, and collaboration in a globalized digital landscape.',
            description: '<p>In our platform, we are committed to providing true multilingual data support, ensuring that users can work with content in multiple languages without encountering barriers or limitations. </p>' +
                '<p>Users can create, edit, and manage content in multiple languages within our platform. Whether it\'s documents, articles, product descriptions, or user-generated content, our system supports the seamless integration of multilingual content into workflows. We offer built-in localization and translation tools to facilitate the adaptation of content across different languages. Users can translate content manually or leverage integrations with third-party translation services for efficient multilingual content management.</p>'+
                '<p>Benefits of True Multilingual Data Support:\n</p>' +
                '<p><div><b>Global Reach:</b></div> Expand your audience and reach users worldwide by offering content and services in multiple languages, catering to diverse linguistic preferences and markets.</p>\n' +
                '<p><div><b>Improved User Experience:</b></div> Enhance user satisfaction and engagement by providing a localized experience that resonates with users\' cultural backgrounds and language proficiency.</p>' +
                '<p><div><b>Efficient Communication:</b></div> Facilitate smooth communication and collaboration among multicultural teams, eliminating language barriers and promoting effective knowledge sharing.</p>' +
                '<p><div><b>Compliance and Localization:</b></div> Meet regulatory requirements and localization standards by managing multilingual content accurately, ensuring legal compliance and cultural sensitivity.</p>' +
                '<p><div><b>Competitive Advantage:</b></div> Gain a competitive edge in global markets by delivering multilingual content and services efficiently, positioning your platform as accessible and user-friendly for international audiences.</p>',
            icon: 'fa fa-language',
            color: 'primary',
            tags: ['multilingual', 'localization', 'compliance']
        },
        {
            id: 'customizable_portals_navigation_page_layouts_list_views', name: 'Customizable Portals, Navigation, Page layouts, List views...',
            caption: 'Features that collectively enhance usability, productivity, and user satisfaction within the platform',
            description: '<p>Our platform offers a comprehensive suite of features designed to empower users with a seamless and personalized experience. From customizable portals to intuitive navigation, flexible page layouts, and interactive list views, our platform provides the tools and capabilities needed to enhance productivity, collaboration, and user satisfaction. </p>' +
                '<p><div><b>Customizable Portals</b></div> With customizable portals, users can create personalized and branded interfaces tailored to their unique needs. Key features include branding and theming options, content customization capabilities, user access control, and seamless integration with third-party tools and services. These portals serve as centralized hubs for accessing information, engaging with content, and performing tasks efficiently.</p>\n' +
                '<p><div><b>Navigation</b></div>Efficient navigation is crucial for user engagement and productivity. Our platform\'s navigation features ensure intuitive and streamlined user experiences. Users can customize navigation menus, utilize breadcrumb trails for easy navigation tracking, and leverage robust search functionality to quickly locate specific content or resources within the platform.</p>' +
                '<p><div><b>Page Layouts</b></div>Page layouts play a vital role in organizing and presenting information effectively. Our platform\'s drag-and-drop editor allows users to create and customize page layouts with ease. These layouts are responsive and optimized for various devices, ensuring consistent user experiences across desktops, tablets, and mobile phones. Integration of interactive widgets, charts, forms, and media elements enriches content presentation and functionality.</p>' +
                '<p><div><b>List Views</b></div> List views provide structured displays of data for easy comprehension and analysis. Our platform\'s list view features enable users to customize columns, apply sorting and filtering options, and perform inline editing directly within list views. This streamlined data management approach enhances efficiency and accuracy in data handling tasks</p>' +
                '<p><div><b>And much more</b></div></p>',

            icon: 'ucf-i-cog',
            color: 'primary',
            tags: ['YOUR-DATA', 'tenant-empowerment', 'user-interaction']
        }
    ];

    constructor(private route:ActivatedRoute, private router: Router){}

    ngOnInit() {
        this.route.params.subscribe( params => {
            this.selectedFeatureId = params['feature'];
            if(this.selectedFeatureId != null) {
                const findFeatures = this.features.filter(feature => feature.id === this.selectedFeatureId);
                if(findFeatures.length == 1) {
                    this.selectedFeature = findFeatures[0];
                } else {
                    this.router.navigate(['features']);

                }
            }
        })
    }

    showMore(feature: Card) {
        this.router.navigate(['features', feature.id] );
    }

    goBack() {
        this.router.navigate(['features'] );

    }
}
