import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';


export enum HttpMethod {
  POST = 'POST',
  GET = 'GET'
}

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  constructor(private httpClient: HttpClient, @Inject('env') private env) {

  }

  headers: HttpHeaders = new HttpHeaders()
      .append('Access-Control-Allow-Origin', this.env.baseUrl)
      .append('Access-Control-Allow-Methods', 'GET, POST')
      .append('Access-Control-Allow-Headers', '*');

  get = function (url, params): Promise<any> {
    let data = new HttpParams();
    if (params != null) {
      Object.keys(params).forEach(key => {
        data = data.set(key, params[key]);
      });
    }
    const options = {params: data};

    return this.getAjax(url, options);

  };

  post(url, param): Promise<any> {
    return this.postAjax(url, param, false);

  }

  private getAjax(url, data): Promise<any> {
    return new Promise((resolve, reject) => {
          this.httpClient.get(url, data ).subscribe({
              next: (result: any) => {
                  const jsonResult = JSON.parse(JSON.stringify(result));
                  resolve(jsonResult);
              },
              error: (e) => console.error(e)
          });
        }
    );
  }

  private postAjax(url, data, withSpinner): Promise<any> {
    return new Promise((resolve, reject) => {


          this.httpClient.post(url, data, {responseType: 'json'}).subscribe({
              next: (result: any) => {
                  return resolve(result);

              },
              error: (e) => console.error(e)
          });
        }
    );
  }


}
