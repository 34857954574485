<div class="position-relative">
    <div class="card bg-gradient-secondary shadow" *ngIf="requestType === 'message'">
        <form #messageForm="ngForm">
            <div class="card-body p-lg-5">
                <h4 class="mb-1">{{title}}</h4>
                <p class="mt-0">{{caption}}</p>
                <div class="row mt-5">
                    <div class="form-group col-6 " [ngClass]="{'focused':focus.fistName===true}">
                        <div class="input-group input-group-alternative">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="ni ni-user-run"></i></span>
                            </div>
                            <input class="form-control" placeholder="First name" type="text" name="firstName"
                                   id="firstName"
                                   [required]="true" [(ngModel)]="messageRequest.firstName"
                                   (focus)="focus.fistName=true" (blur)="focus.fistName=false"
                                   #firstName="ngModel"
                                   [ngClass]="{ 'is-invalid': firstName.errors && (firstName.touched || messageForm.submitted) }">
                        </div>
                    </div>
                    <div class="form-group col-6 " [ngClass]="{'focused':focus.lastName===true}">
                        <div class="input-group input-group-alternative">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="ni ni-user-run"></i></span>
                            </div>
                            <input class="form-control" placeholder="Last Name" type="text" name="lastName"
                                   [required]="true" [(ngModel)]="messageRequest.lastName" (focus)="focus.lastName=true"
                                   (blur)="focus.lastName=false"
                                   #lastName="ngModel"
                                   [ngClass]="{ 'is-invalid': lastName.errors && (lastName.touched || messageForm.submitted) }">
                        </div>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'focused':focus.email===true}">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                        </div>
                        <input class="form-control" placeholder="Email address" type="email" name="email"
                               required email [(ngModel)]="messageRequest.email" (focus)="focus.email=true"
                               (blur)="focus.email=false"
                               #email="ngModel"
                               [ngClass]="{ 'is-invalid': email.errors && (email.touched || messageForm.submitted)}">
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'focused':focus.companyName===true}">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-building"></i></span>
                        </div>
                        <input class="form-control" placeholder="Company Name" type="text" name="companyName"
                               [required]="true" [(ngModel)]="messageRequest.companyName"
                               (focus)="focus.companyName=true" (blur)="focus.companyName=false"
                               #companyName="ngModel"
                               [ngClass]="{ 'is-invalid': companyName.errors && (companyName.touched || messageForm.submitted) }">
                    </div>
                </div>
                <div class="form-group mb-4">
            <textarea class="form-control form-control-alternative" name="message" rows="4"
                      [required]="true" [(ngModel)]="messageRequest.message" cols="80" placeholder="Type a message..."
                      #message="ngModel"
                      [ngClass]="{ 'is-invalid': message.errors && (message.touched || messageForm.submitted) }"
            ></textarea>
                </div>
                <div>
                    <button type="button" class="btn btn-default btn-round btn-block btn-lg"
                            (click)="onMessageSubmit(messageForm)">Send Message
                    </button>
                </div>
            </div>
        </form>
    </div>

    <div class="card bg-gradient-secondary shadow" *ngIf="requestType === 'schedule_demo'">
        <form #demoForm="ngForm">
            <div class="card-body p-lg-5">
                <h4 class="mb-1">{{title}}</h4>
                <p class="mt-0">{{caption}}</p>
                <div class="row mt-5">
                    <div class="form-group col-6 " [ngClass]="{'focused':focus.fistName===true}">
                        <div class="input-group input-group-alternative">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="ni ni-user-run"></i></span>
                            </div>
                            <input class="form-control" placeholder="First name" type="text" name="firstName"
                                   id="firstName"
                                   [required]="true" [(ngModel)]="demoRequest.firstName" (focus)="focus.fistName=true"
                                   (blur)="focus.fistName=false"
                                   #firstName="ngModel"
                                   [ngClass]="{ 'is-invalid': firstName.errors && (firstName.touched || demoForm.submitted) }">
                        </div>
                    </div>
                    <div class="form-group col-6 " [ngClass]="{'focused':focus.lastName===true}">
                        <div class="input-group input-group-alternative">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="ni ni-user-run"></i></span>
                            </div>
                            <input class="form-control" placeholder="Last Name" type="text" name="lastName"
                                   [required]="true" [(ngModel)]="demoRequest.lastName" (focus)="focus.lastName=true"
                                   (blur)="focus.lastName=false"
                                   #lastName="ngModel"
                                   [ngClass]="{ 'is-invalid': lastName.errors && (lastName.touched || demoForm.submitted) }">
                        </div>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'focused':focus.email===true}">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                        </div>
                        <input class="form-control" placeholder="Email address" type="email" name="email"
                               required email [(ngModel)]="demoRequest.email" (focus)="focus.email=true"
                               (blur)="focus.email=false"
                               #email="ngModel"
                               [ngClass]="{ 'is-invalid': email.errors && (email.touched || demoForm.submitted)}">
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'focused':focus.companyName===true}">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-building"></i></span>
                        </div>
                        <input class="form-control" placeholder="Company Name" type="text" name="companyName"
                               [required]="true" [(ngModel)]="demoRequest.companyName" (focus)="focus.companyName=true"
                               (blur)="focus.companyName=false"
                               #companyName="ngModel"
                               [ngClass]="{ 'is-invalid': companyName.errors && (companyName.touched || demoForm.submitted) }">
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'focused':focus.jobTitle===true}">
                    <div class="input-group input-group-alternative">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="ni ni-briefcase-24"></i></span>
                        </div>
                        <input class="form-control" placeholder="Job Title" type="text" name="jobTitle"
                               [required]="true" [(ngModel)]="demoRequest.jobTitle" (focus)="focus.jobTitle=true"
                               (blur)="focus.jobTitle=false"
                               #jobTitle="ngModel"
                               [ngClass]="{ 'is-invalid': jobTitle.errors && (jobTitle.touched || demoForm.submitted) }">
                    </div>
                </div>
                <div>
                    <button type="button" class="btn btn-default btn-round btn-block btn-lg"
                            (click)="onDemoRequestSubmit(demoForm)">Send request
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="block-ui" *ngIf="submitInProgress">
        <div class="text-center text-default font-weight-bold">
            <i class="fa fa-spin fa-spinner" style="font-size: 4rem;"></i>
        </div>
    </div>
    <div class="block-ui" *ngIf="successfullySubmitted">
        <div class="text-center text-default font-weight-bold">
            <i class="fa fa-check-circle-o mb-2" style="font-size: 4rem;"></i>
            <p class="font-weight-bold" style="font-size: 1.4rem;">Your request has been successfully submitted!</p>
            <p class="font-weight-bold" style="font-size: 1.4rem;">Our team will reach out to you as soon as
                possible.</p>
        </div>
    </div>

    <div class="block-ui" *ngIf="showRecaptcha">
        <div class="text-center">
            <re-captcha (resolved)=" resolvedRecaptcha($event)"
                        [siteKey]="env.reCaptchaKey"></re-captcha>
        </div>
    </div>
</div>
