<main>
  <app-header
    title="UCF - Application Platform"
    caption="Innovative enterprise software development framework"
    description="Create your App Quick, Safe, Scalable and Fully customizable!"
    [buttons]="headerButtons"
  ></app-header>

  <section class="section section-md ">
    <div class="container">
      <div class="row row-grid justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="display-3">Do you need reliable and fast solution for Information System?
            <span class="text-success">You are in the right place!</span>
          </h2>
          <p class="lead"> UCF provides completely new approach to No-Code and Low-Code Information System development process.</p>
          <p class="lead">UCF features a comprehensive engine for building and running Information Systems, delivering superior cost-effectiveness, performance, quality, and security compared to conventional development methods.</p>

        </div>
      </div>
    </div>
  </section>

  <section class="section pb-0 bg-gradient-primary">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-lg-2 order-2 ml-lg-auto">
          <div class="position-relative pl-md-5">
            <app-img src="./assets/img/ucf/ucf_configuration_2.png" [styleClass]="'img-center img-fluid'"></app-img>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1 order-1 mt-0">
          <div class="d-flex px-3">
            <div>
              <div class="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i class="ni ni-building text-primary"></i>
              </div>
            </div>
            <div class="pl-4">
              <h4 class="display-3 text-white">What is UCF?</h4>
              <p class="text-white text-white pt-2  mb-md font-weight-400">UCF is an innovative No-Code/Low-Code Framework that offers a wide variety of software solutions for both End Corporate Clients as well as Service providers (SaaS) and Startups.</p>
              <p class="text-white text-white mb-lg  font-weight-400">UCIS platform allows multiple models of software product build and deployment process, while maintaining a wide coverage of industries and products that can be built on UCF technologies.
                To better understand UCF and the product and service offerings, please see our Products and Services page.</p> <!--TODO Jelena link to start of Products and Services page-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SVG separator -->
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>

  <section class="section section-lg pt-lg-0">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-1">
          <app-img src="./assets/img/ucf/ucf_configuration_1.png" [styleClass]="'img-fluid img-fluid'"></app-img>

        </div>
        <div class="col-md-6 order-md-2">
          <div class="pl-md-5">
            <div class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
              <i class="ni ni-world"></i>
            </div>
            <h3>Unlock limitless app potential with our feature-rich platform</h3>
            <p>Our platform provides the foundation you need to bring your ideas to life, faster and with unparalleled flexibility. </p>
            <p>Experience the freedom to innovate, create, and thrive with UCF. </p>
            <ul class="list-unstyled mt-5">
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-html5"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Fast Information System building</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="ni ni-settings-gear-65"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Fully customizable</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="fa fa-cloud"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Super scalable and reliable</h6>
                  </div>
                </div>
              </li>
              <li class="py-2">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="badge badge-circle badge-success mr-3">
                      <i class="fa fa-shield"></i>
                    </div>
                  </div>
                  <div>
                    <h6 class="mb-0">Secure and Robust</h6>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section bg-secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-4 p-0" *ngFor="let feature of features">
          <app-card [card]="feature" [styleType]="'style_2'" [showBorder]="false" [showShadow]="false" [styleClass]="'bg-secondary'"></app-card>
        </div>
      </div>
    </div>
  </section>

  <section class="section section-lg bg-gradient-default">
    <div class="container pt-sm pb-300">
      <div class="row text-center justify-content-center">
        <div class="col-lg-10">
          <h2 class="display-3 text-white">Build your Information System now!</h2>
          <p class="lead text-white">Our cutting-edge platform empowers developers and businesses alike to unleash their creativity and build exceptional apps effortlessly. With a comprehensive suite of pre-built features and powerful tools at your fingertips, the possibilities are endless. </p>
        </div>
      </div>
    </div>
    <!-- SVG separator -->
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <section class="section section-lg pt-lg-0 section-contact-us">
    <div class="container">
      <div class="row justify-content-center mt--300">
        <div class="col-lg-8">
          <app-send-request title="Are you interested in our platform?" caption="Every feedback is important to us."></app-send-request>
        </div>
      </div>
    </div>
  </section>

</main>
