import {Component, Input, OnInit} from '@angular/core';
import {Card} from './card';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: [],
    providers: [NgbPopoverConfig],
})

export class CardComponent implements OnInit {
    @Input() card: Card;
    @Input() showShadow?: boolean = true;
    @Input() showBorder?: boolean = true;
    @Input() styleClass?: string;
    @Input() styleType?: 'style_1' | 'style_2' | 'style_3' = 'style_1';

    ngOnInit(): void {
    }
}
