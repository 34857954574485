import {Component} from '@angular/core';
import {Card} from '../common/card/card';

@Component({
    selector: 'app-benefits',
    templateUrl: './benefits.component.html',
    styleUrls: []
})

export class BenefitsComponent {
    // @Input()
    benefits: Card[] = [
        {
            name: 'Fast No-Code or Low-Code Development',
            id: 'test',
            caption: "Rapidly craft stunning products with minimal coding.",
            description: 'Experience the power of rapid development with UCF\'s no-code or low-code approach. Whether you\'re a seasoned developer or just starting out, our platform empowers you to build remarkable products swiftly and effortlessly. Say goodbye to lengthy coding sessions and hello to intuitive tools that accelerate your development journey. With UCF, unleash your creativity and bring your vision to life in record time.',
            icon: 'ni-check-bold',
            color: 'success',
            tags: ['rapid', 'no-code', 'low-code', 'efortless', 'intuitive']
        },
        {
            name: 'Effortless SaaS Software Development',
            id: 'test',
            caption: 'UCF - Where SaaS is seamlessly woven into your product.',
            description: 'UCF offers native and versatile support for SaaS product development. With UCF, SaaS software development seamlessly integrates into your workflow, requiring minimal effort. Our intuitive tools and streamlined processes empower you to focus on innovation rather than grappling with the technical complexities of SaaS software. Accelerate your time to market and stay ahead in today\'s dynamic digital landscape by effortlessly bringing your SaaS product to life.',
            icon: 'ni-check-bold',
            color: 'success',
            tags: ['SaaS', 'native', 'robust']
        },
        {
            name: 'Dual-Level Product Configuration',
            id: 'test',
            caption: 'Empowering both architects and tenants for tailored experiences.',
            description: 'With UCF\'s dual-level product configuration, you have the flexibility to shape your software environment at two distinct levels. At the build time, architects can effortlessly modify the fundamental design of the product, ensuring it aligns perfectly with overarching objectives. Meanwhile, tenants enjoy the autonomy to perform individualized run-time modifications, tailoring their experience within the SaaS system to their specific needs. This unique approach fosters unparalleled flexibility and adaptability, allowing each participant to create and customize their system according to their unique requirements.',
            icon: 'ni-check-bold',
            color: 'success',
            tags: ['Product-configuration', 'Build-time', 'Tenant-customization', 'Flexibility', 'Adaptability']
        },
    ]
}
