import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-products',
    templateUrl: './product.component.html',
    styleUrls: []
})

export class ProductsComponent implements OnInit {
    productType;

    constructor(private route:ActivatedRoute){}
    ngOnInit(): void {
        this.route.params.subscribe( params => {
            this.productType = params['type'];
            let el = document.getElementById(this.productType);
            if(el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        })
    }

}
