<footer class="footer" [ngClass]="{'has-cards': getPath()!=='/user-profile' && getPath()!=='/register' && getPath()!=='/login'}">

  <div class="container">
    <div class="row row-grid align-items-center mt-md" >
      <div class="col-lg-6">
        <h3 class="text-primary font-weight-light mb-2">Thank you for supporting us!</h3>
        <h4 class="mb-0 font-weight-light">Let's stay in touch!</h4>
      </div>
<!--      <div class="col-lg-6 text-lg-center btn-wrapper">-->
<!--        <a target="_blank" href="https://twitter.com/creativetim" class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg" ngbTooltip="Follow us">-->
<!--          <i class="fa fa-twitter"></i>-->
<!--        </a>-->
<!--        <a target="_blank" href="https://www.facebook.com/creativetim" class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" ngbTooltip="Like us">-->
<!--          <i class="fa fa-facebook-square"></i>-->
<!--        </a>-->
<!--        <a target="_blank" href="https://dribbble.com/creativetim" class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round" ngbTooltip="Follow us">-->
<!--          <i class="fa fa-dribbble"></i>-->
<!--        </a>-->
<!--        <a target="_blank" href="https://github.com/creativetimofficial" class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg" ngbTooltip="Star on Github">-->
<!--          <i class="fa fa-github"></i>-->
<!--        </a>-->
<!--      </div>-->
    </div>
    <hr>
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          &copy; {{test | date: 'yyyy'}} UCF Team. All Rights Reserved.
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a [routerLink]="['/home']" class="nav-link" target="_blank">Home</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/contact-us']" class="nav-link" target="_blank">Contact Us</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/faq']"class="nav-link" target="_blank">FAQ</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
