import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: []
})

export class NotFoundComponent implements OnInit {
    ngOnInit(): void {
    }

}
