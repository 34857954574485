<div *ngIf="!large">
    <span *ngFor="let item of items">
    <a [routerLink]="[item.routerLink]" class="dropdown-item">{{item.label}}</a>
    </span>
</div>
<div class="dropdown-menu-xl"  *ngIf="large">
    <div class="dropdown-menu-inner">
        <div *ngFor="let item of items" class="p-2">
        <a [routerLink]="[item.routerLink]" class="media d-flex align-items-center">
            <div class="icon icon-shape bg-primary rounded-circle text-white" [ngClass]="item.iconClass">
                <i class="ni" [ngClass]="item.icon"></i>
            </div>
            <div class="media-body ml-3">
                <h6 class="font-weight-600 text-primary mb-md-1" [ngClass]="item.textClass">{{item.label}}</h6>
                <p class="description d-none d-md-inline-block mb-0">{{item.description}}</p>
            </div>
        </a>
        </div>
    </div>
</div>
