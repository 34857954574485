import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../MenuItem';

@Component({
    selector: 'app-dropdown-menu',
    templateUrl: './dropdown-menu.component.html',
    styleUrls: []
})
export class DropdownMenuComponent implements OnInit {
    @Input() items: MenuItem[];
    @Input() large?: boolean = false;

    ngOnInit(): void {
    }

}
