<main>
    <app-header
            caption="Investors relations"
            description="Create your App Quick, Safe, Scalable and Fully customizable!"
    ></app-header>

    <section class="section section-sm ">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-lg-10 text-center">
                    <p class="lead">At UCF, our vision is to empower businesses and organizations with a flexible and scalable platform that enables them to create tailored information systems to meet their unique needs and objectives. As a startup, we have been self-funded thus far and have successfully developed 80% of our platform. </p>
                    <p class="lead">We are open to discussions about potential collaborations and investments that align with our mission of revolutionizing information system development. Whether you're interested in strategic partnerships, joint ventures, or investment opportunities, we welcome the opportunity to explore mutually beneficial relationships.</p>

                </div>
            </div>
        </div>
    </section>


    <section class="section section-lg pt-0">
        <div class="container">
            <div class="card bg-gradient-primary shadow-lg border-0">
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <p class="lead text-white mt-3">For inquiries regarding collaborations, investments, or general inquiries, please contact us </p>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <a class="btn btn-lg btn-block btn-white" [routerLink]="['/contact-us']">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
