import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html',
    styleUrls: ['img.component.scss']
})

export class ImgComponent {
    @Input() src: string;
    @Input() styleClass: string;

    imageFullscreenView = false;


    open() {
        this.imageFullscreenView = true;
    }
    close() {
        this.imageFullscreenView = false;
    }

    onClickedOutside($event: any) {
        this.close();
    }
}
