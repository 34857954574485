import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {LandingComponent} from './landing/landing.component';
import {FeaturesComponent} from './features/features.component';
import {FaqComponent} from './faq/faq.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {BenefitsComponent} from './benefits/benefits.component';
import {DemoComponent} from './demo/demo.component';
import {ProductsComponent} from './products/products.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {InvestorRelationsComponent} from './investor-relations/investor-relations.component';

const routes: Routes =[
    { path: '',                     component: HomeComponent },
    { path: 'home',                 component: HomeComponent },
    { path: 'features',             component: FeaturesComponent },
    { path: 'features/:feature',    component: FeaturesComponent },
    { path: 'benefits',             component: BenefitsComponent },
    { path: 'investor-relations',   component: InvestorRelationsComponent },
    { path: 'demo',                 component: DemoComponent },
    { path: 'faq',                  component: FaqComponent },
    { path: 'contact-us',           component: ContactUsComponent },
    { path: 'products',             component: ProductsComponent },
    { path: 'products/:type',          component: ProductsComponent },

    { path: 'landing',              component: LandingComponent },
    { path: '**',                   component: NotFoundComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [],
})
export class AppRoutingModule {
}
