<main>
    <app-header
            caption="Find quick answers to common questions about our platform."
            description="Create your App Quick, Safe, Scalable and Fully customizable!"
    ></app-header>

    <section class="section section-lg pt-lg-0">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 pt-lg-4">
                    <div ngbAccordion #accordion="ngbAccordion" class="accordion accordion-flush">
                        <div ngbAccordionItem="{{faq.id}}" *ngFor="let faq of faqList">
                            <h2 ngbAccordionHeader>
                                <button ngbAccordionButton>{{faq.question}}</button>
                            </h2>
                            <div ngbAccordionCollapse>
                                <div ngbAccordionBody>
                                    <ng-template>
                                        {{faq.answer}}
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section section-lg pt-0">
        <div class="container">
            <div class="card bg-gradient-info shadow-lg border-0">
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-white">Can't find what you need?</h3>
                            <p class="lead text-white mt-3">Don't hesitate to reach out to our dedicated support team for personalized assistance.</p>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <a class="btn btn-lg btn-block btn-white" [routerLink]="['/contact-us']">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>
