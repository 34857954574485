<main>
    <app-header
            description="Discover the full potential of our platform, witness how seamlessly it integrates with your business, and experience its user-friendly nature firsthand. Don't hesitate to schedule a personalized demo today."
    ></app-header>

    <section class="section section-lg pt-lg-0 section-contact-us mt--100">
        <div class="container">
            <div class="row justify-content-center ">
                <div class="col-lg-8">
                    <app-send-request title="Schedule a demo" [requestType]="'schedule_demo'"></app-send-request>
                </div>
            </div>
        </div>
    </section>

    <section class="section section-lg pt-0">
        <div class="container">
            <div class="card bg-gradient-default shadow-lg border-0">
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-white">Do you want to see it in action right now?</h3>
                            <p class="lead text-white mt-3">Explore our YouTube channel, where you'll discover insightful snippets showcasing the platform.</p>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <a class="btn btn-lg btn-block btn-white" target="_blank"  href="https://www.youtube.com/@UCFPaaS">YouTube Chanel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
